const mapDispatchToProps = (dispatch) => {
	return {
		reduxFunctions: {
			swithcLanguage: function(lang) {
				dispatch({ type: `SWITCH`, lang: lang });
			},
			addTranslationPage: function(currentPage, lang, slug) {
				var obj = {};
				obj[lang] = slug;
				dispatch({ type: `ADD_TRANSLATION_PAGE`, currentPage: currentPage, translationPage: obj });
			},
			setCurrentPage: function(slug) {
				dispatch({ type: `SET_CURRENT_PAGE`, currentPage: slug });
			},
			resetTranslationPage: function() {
				dispatch({ type: `RESET_TRANSLATION_PAGE` });
			},
			toggleMobileMenu: function() {
				dispatch({ type: `TOGGLE_MOBILE_MENU` });
			},
		},
	};
};

exports.mapDispatchToProps = mapDispatchToProps;
