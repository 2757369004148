const mapStateToProps = (state) => {
	return {
		state: {
			lang: state.lang,
			currentTranslationPages: state.currentTranslationPages,
			currentPage: state.currentPage,
			translation: state.translation,
			visibility: state.visibility,
		},
	};
};

exports.mapStateToProps = mapStateToProps;
