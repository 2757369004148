// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

class Translate extends Component {
	render() {
		const children = this.props.children;
		const translation = this.props.state.translation;

		let translated =
			children &&
			this.props.state.lang &&
			translation &&
			translation[this.props.state.lang] &&
			translation[this.props.state.lang][children]
				? translation[this.props.state.lang][children]
				: '';

		return <> {translated} </>;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Translate);
