import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { catalogs } from '../../i18n';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Translate from '../../components/Translate';

import styles from './style.module.css';

class LanguageSwitcher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.state.lang || 'it',
		};
	}

	change(value) {
		this.props.reduxFunctions.swithcLanguage(value);
		const pageToGo =
			this.props.state.currentTranslationPages[this.props.state.currentPage] &&
			this.props.state.currentTranslationPages[this.props.state.currentPage][value]
				? this.props.state.currentTranslationPages[this.props.state.currentPage][value]
				: `/${value}/`;
		navigate(pageToGo);
	}
	render() {
		const languages = this.props.languages;
		let switcher = '';
		let languagesOptions =
			languages && languages.length !== 0
				? languages.map(function(item) {
						return (
							<option key={item} value={item} aria-label={item}>
								{catalogs[this.props.state.lang]['languageSwitcher.languages.' + item]}
							</option>
						);
				  }, this)
				: [];

		let languagesItems =
			languages && languages.length !== 0
				? languages.map(function(item) {
						return (
							<li key={item} value={item} aria-label={item}>
								<div role="link" onClick={() => this.change(item)} tabIndex="0">
									<p
										className={
											styles.paragraphWithFlag +
											' ' +
											styles[item] +
											' ' +
											(this.props.state.lang === item ? styles.selected : styles.notSelected)
										}>
										<Translate>{'languageSwitcher.languages.' + item}</Translate>
									</p>
								</div>
							</li>
						);
				  }, this)
				: [];

		if (!this.props.mobile) {
			switcher = (
				<div className={styles.languageSelect}>
					<select
						onChange={(e) => this.change(e.target.value)}
						value={this.state.value}
						aria-label={catalogs[this.props.state.lang]['languageSwitcher.aria.select']}
						className={styles.selectWithFlag + ' ' + styles[this.props.state.lang]}>
						{languagesOptions}
					</select>
				</div>
			);
		} else {
			// this.props.mobile === true
			switcher = (
				<div className={styles.languageListWrapper + ' ' + this.props.className}>
					<h3 className={styles.languageListTitle}>
						<Translate>languageSwitcher.mobile.title</Translate>
					</h3>
					<ul
						aria-label={catalogs[this.props.state.lang]['languageSwitcher.aria.listSwitcher']}
						className={styles.languageList}>
						{languagesItems}
					</ul>
				</div>
			);
		}

		return switcher;
	}
}

class ConnectedLanguageSwitcher extends Component {
	render() {
		return <LanguageSwitcher languages={this.props.languages} {...this.props} />;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedLanguageSwitcher);
