import { Link } from 'gatsby';
import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import LanguageSwitcher from '../LanguageSwitcher';
import CrossIcon from '../CrossIcon';

import styles from './style.module.css';

class MobileMenu extends Component {
	toggleMobileMenu() {
		this.props.reduxFunctions.toggleMobileMenu();
	}
	sanitize(url) {
		let urlSanitized = url;

		if (
			url.indexOf('http://panel.gamgroup.it') !== -1
		) { // This means that is not a custom link on WP but a page or a post
			urlSanitized = url.replace('http://panel.gamgroup.it','');
		}

		return urlSanitized;
	}
	render() {
		let menu = this.props.data.menus.edges.filter((edge) => {
			return edge.node.name === 'nav-menu-' + this.props.state.lang;
		});
		menu = menu[0].node;

		var languages = this.props.languages;

		return (
			<nav
				className={styles.flyoutMenu + ' ' + styles[this.props.state.visibility] + ' ' + styles.gridItem1}
				onClick={(e) => this.toggleMobileMenu(e)}>
				<ul className={styles.listFlex}>
					{menu.items.map((i) => {
						const linkTo = `/${this.props.state.lang}${this.sanitize(i.url)}`; // Remember to set URL on Worpress with double slash
						return (
							<li key={i.title} aria-label={i.title}>
								<Link
									to={linkTo}
									dangerouslySetInnerHTML={{ __html: i.title }}
									className={this.props.state.currentPage === linkTo ? styles.selected : styles.notSelected}
								/>
							</li>
						);
					}, this)}
				</ul>
				<CrossIcon className={styles.gridItem2} />
				<LanguageSwitcher languages={languages} mobile={true} className={styles.gridItem3} />
			</nav>
		);
	}
}

class ConnectedMobileMenu extends Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					query {
						languagesAvailable: allWordpressPage {
							group(field: polylang_current_lang_code) {
								field
								fieldValue
								totalCount
							}
						}
						menus: allWordpressWpApiMenusMenusItems {
							edges {
								node {
									id
									name
									items {
										title
										url
										object_slug
									}
								}
							}
						}
					}
				`}
				render={(data) => <MobileMenu data={data} {...this.props} />}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedMobileMenu);
