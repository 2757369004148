import React, { Component } from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

class MainMenu extends Component {
	sanitize(url) {
		let urlSanitized = url;

		if (url.indexOf('http://panel.gamgroup.it') !== -1) {
			// This means that is not a custom link on WP but a page or a post
			urlSanitized = url.replace('http://panel.gamgroup.it', '');
		}

		return urlSanitized;
	}
	render() {
		let menu = this.props.menu.edges.filter((edge) => {
			return edge.node.name === 'nav-menu-' + this.props.state.lang;
		});
		menu = menu[0].node;

		return (
			<nav className={styles.navigation}>
				<ul className={styles.listFlex}>
					{menu.items.map((i) => {
						const linkTo = `/${this.props.state.lang}${this.sanitize(i.url)}`;
						return (
							<li key={i.title} className={styles.itemList} aria-label={i.title}>
								<Link
									className={this.props.state.currentPage === linkTo ? styles.selected : styles.notSelected}
									to={linkTo}
									dangerouslySetInnerHTML={{ __html: i.title }}
								/>
							</li>
						);
					}, this)}
				</ul>
			</nav>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MainMenu);
