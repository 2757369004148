import { Link } from 'gatsby';
import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

import { catalogs } from '../../i18n';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Headroom from 'react-headroom';
import LanguageSwitcher from '../LanguageSwitcher';
import MainMenu from '../MainMenu';

import logo from '../../assets/images/logo.png';
import styles from './style.module.css';

class Header extends Component {
	toggleMobileMenu() {
		this.props.reduxFunctions.toggleMobileMenu();
	}
	render() {
		const menu = this.props.data.menus;

		var languages = this.props.languages;

		return (
			<header className={styles.headerStyle}>
				<Headroom>
					<div className={styles.gridContainer + ' applicationWrapper'}>
						<section className={styles.sectionFlex}>
							<h1 className={styles.headerTitle}>
								<Link to={`/${this.props.state.lang}`} className={styles.noBorder}>
									<img
										className={styles.logoImage}
										src={logo}
										alt={catalogs[this.props.state.lang]['header.alt.gamLogo']}
										aria-label={catalogs[this.props.state.lang]['header.aria.gamLogo']}
									/>
								</Link>
							</h1>
						</section>
						<section
							className={
								styles.sectionFlex + ' ' + styles.sectionMenu + ' ' + styles.sectionFlexMenu + ' hideOnMobile'
							}>
							<MainMenu menu={menu} lang={this.props.state.lang} />
						</section>
						<section className={styles.sectionFlex + ' ' + styles.sectionFlexRight + ' hideOnMobile'}>
							<LanguageSwitcher languages={languages} mobile={false} />
						</section>
						<section className={'hideOnDesktop ' + styles.gridItemHamburger}>
							<div>
								<div className={styles.bmBurgerButton}>
									<span>
										<span className={styles.bmBurgerBars + ' ' + styles.bmBurgerBars1} />
										<span className={styles.bmBurgerBars + ' ' + styles.bmBurgerBars2} />
										<span className={styles.bmBurgerBars + ' ' + styles.bmBurgerBars3} />
									</span>
									<button onClick={(e) => this.toggleMobileMenu(e)} className={styles.bmBurgerButtonClick}>
										Open Menu
									</button>
								</div>
							</div>
						</section>
					</div>
				</Headroom>
			</header>
		);
	}
}

class ConnectedHeader extends Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					query {
						menus: allWordpressWpApiMenusMenusItems {
							edges {
								node {
									id
									name
									items {
										title
										url
										object_slug
									}
								}
							}
						}
					}
				`}
				render={(data) => <Header data={data} {...this.props} />}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedHeader);
