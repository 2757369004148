import { Link } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { catalogs } from '../../i18n';
import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import Translate from '../../components/Translate';

import logo from '../../assets/images/logo.png';
import styles from './style.module.css';

class Footer extends Component {
	sanitize(url) {
		let urlSanitized = url;

		if (
			url.indexOf('http://panel.gamgroup.it') !== -1
		) { // This means that is not a custom link on WP but a page or a post
			urlSanitized = url.replace('http://panel.gamgroup.it','');
		}

		return urlSanitized;
	}
	render() {
		let menu = this.props.data.menus.edges.filter((edge) => {
			return edge.node.name === 'nav-menu-' + this.props.state.lang;
		});
		menu = menu[0] && menu[0].node;

		let legalMenu = this.props.data.menus.edges.filter((edge) => {
			return edge.node.name === 'legal-menu-' + this.props.state.lang;
		});
		legalMenu = legalMenu[0] && legalMenu[0].node;

		return (
			<footer role="contentinfo" className={styles.footerStyle}>
				<div className={styles.gridContainer + ' applicationWrapper'}>
					<section
						className={styles.gridItem + ' ' + styles.gridItem1}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.gamSection']}>
						<h2 className={styles.footerTitle}>
							<img
								className={styles.logoImage}
								src={logo}
								alt={catalogs[this.props.state.lang]['footer.alt.gamLogo']}
								aria-label={catalogs[this.props.state.lang]['footer.aria.gamLogo']}
							/>
						</h2>
						<p aria-label={catalogs[this.props.state.lang]['footer.aria.gamDescription']}>
							<Translate>footer.gamDescription</Translate>
						</p>
					</section>

					<section
						className={styles.gridItem + ' ' + styles.gridItem2}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.exploreSection']}>
						<h2 className={styles.footerTitle}>
							<Translate>footer.exploreSection</Translate>
						</h2>
						<ul className={styles.footerList}>
							{menu.items.map((i) => {
								const linkTo = `/${this.props.state.lang}${this.sanitize(i.url)}`; // Remember to set URL on Worpress with double slash
								return (
									<li key={i.title}>
										<Link to={linkTo} dangerouslySetInnerHTML={{ __html: i.title }} className="whiteLink" />
									</li>
								);
							}, this)}
						</ul>
					</section>

					<section
						className={styles.gridItem + ' ' + styles.gridItem3}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.visitSection']}>
						<h2 className={styles.footerTitle}>
							<Translate>footer.visitSection</Translate>
						</h2>
						<address>
							<ul className={styles.footerList}>
								<li>
									<p aria-label={catalogs[this.props.state.lang]['footer.aria.address_1']}>
										<Translate>footer.address_1</Translate>
									</p>
								</li>
								<li>
									<p aria-label={catalogs[this.props.state.lang]['footer.aria.address_2']}>
										<Translate>footer.address_2</Translate>
									</p>
								</li>
								<li>
									<p aria-label={catalogs[this.props.state.lang]['footer.aria.address_3']}>
										<Translate>footer.address_3</Translate>
									</p>
								</li>
							</ul>
						</address>
					</section>

					<section
						className={styles.gridItem + ' ' + styles.gridItem4}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.socialSection']}>
						<h2 className={styles.footerTitle}>
							<Translate>footer.socialSection</Translate>
						</h2>
						<ul className={styles.footerList}>
							<li>
								<a
									className="whiteLink"
									href="https://www.linkedin.com/company/g-a-m-group-s-r-l-/"
									aria-label={catalogs[this.props.state.lang]['footer.aria.linkedin']}>
									<Translate>footer.linkedin</Translate>
								</a>
							</li>
							<li>
								<a
									className="whiteLink"
									href="https://www.facebook.com/GAM-GROUP-118820254803358/"
									aria-label={catalogs[this.props.state.lang]['footer.aria.facebook']}>
									<Translate>footer.facebook</Translate>
								</a>
							</li>
						</ul>
					</section>

					<section
						className={styles.gridItem + ' ' + styles.gridItem5}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.legalSection']}>
						<h2 className={styles.footerTitle}>
							<Translate>footer.legalSection</Translate>
						</h2>
						<ul className={styles.footerList}>
							{legalMenu.items.map((i) => {
								const linkTo = `/${this.props.state.lang}/${i.object_slug}/`;
								return (
									<li key={i.title}>
										<Link to={linkTo} dangerouslySetInnerHTML={{ __html: i.title }} className="whiteLink" />
									</li>
								);
							})}
						</ul>
					</section>

					<section
						className={styles.gridItem + ' ' + styles.gridItem6}
						role="contentinfo"
						aria-label={catalogs[this.props.state.lang]['footer.aria.finalSection']}>
						<p aria-label={catalogs[this.props.state.lang]['footer.aria.copyright']}>
							<Translate>footer.copyright</Translate>
						</p>
						<address className={styles.paddingAround}>
							<a
								className="whiteLink"
								href={catalogs[this.props.state.lang]['footer.mailto.email']}
								aria-label={catalogs[this.props.state.lang]['footer.aria.email']}>
								<Translate>footer.email</Translate>
							</a>
							<span> - </span>
							<a
								className="whiteLink"
								href={catalogs[this.props.state.lang]['footer.tel.number']}
								aria-label={catalogs[this.props.state.lang]['footer.aria.number']}>
								<Translate>footer.number</Translate>
							</a>
						</address>
					</section>
				</div>
			</footer>
		);
	}
}

class ConnectedFooter extends Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					query {
						languagesAvailable: allWordpressPage {
							group(field: polylang_current_lang_code) {
								field
								fieldValue
								totalCount
							}
						}
						menus: allWordpressWpApiMenusMenusItems {
							edges {
								node {
									id
									name
									items {
										title
										url
										object_slug
									}
								}
							}
						}
					}
				`}
				render={(data) => <Footer data={data} {...this.props} />}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedFooter);
