// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';

import styles from './style.module.css';

class Translate extends Component {
	render() {
		return <div className={styles.close + ' ' + this.props.className}> </div>;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Translate);
