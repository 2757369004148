import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';

import '../../styles/variables.css';
import '../../styles/commonClasses.css';
import '../../styles/main.css';

import { mapDispatchToProps } from '../../services/MapDispatchToProps';
import { mapStateToProps } from '../../services/MapStateToProps';
import isIE from '../../config/IsIE11';

import styles from './style.module.css';

class Layout extends Component {
	constructor(args) {
		super(args);
		this.languages = [];
		if (typeof window !== 'undefined') {
			window.addEventListener(
				'popstate',
				function() {
					let urlLang = window.location.pathname.split('/');
					if (
						args &&
						args.reduxFunctions &&
						args.reduxFunctions.swithcLanguage &&
						typeof args.reduxFunctions.swithcLanguage === 'function' &&
						urlLang &&
						urlLang.length > 2
					) {
						args.reduxFunctions.swithcLanguage(urlLang[1]);
					}
				},
				false
			);
		}
	}
	render() {
		const children = React.Children.map(this.props.children, (child, index) => {
			return React.cloneElement(child, {
				index,
				lang: this.props.lang,
			});
		});
		const languagesAvailableQueryResult = this.props.data.languagesAvailable;
		let page = null;

		this.languages =
			languagesAvailableQueryResult &&
			languagesAvailableQueryResult.group &&
			languagesAvailableQueryResult.group.length !== 0
				? languagesAvailableQueryResult.group.map(function(item) {
						return item.fieldValue;
				  })
				: [];
		const metadata = this._getMeta();
		if (!isIE()) {
			page = (
				<>
					<Helmet title={metadata.title} meta={metadata.meta} link={metadata.link}>
						<html lang={this.props.state.lang} />
					</Helmet>
					<Header languages={this.languages} />
					<MobileMenu languages={this.languages} />
					<main className={styles.mainWrapper}>{children}</main>
					<Footer />
				</>
			);
		} else {
			page = (
				<>
					<Helmet title={metadata.title} meta={metadata.meta} link={metadata.link}>
						<html lang={this.props.state.lang} />
					</Helmet>
					<div>
						<p> You are using an old browser, please update to a new one.</p>
					</div>
				</>
			);
		}

		return page;
	}

	_getMeta() {
		let yoast = {
			title: '',
			metadesc: '',
			metakeywords: '',
			social_title: '',
			social_description: '',
			social_image: '',
			canonical: '',
			noindex_nofollow: '',
		};
		if (this.props.metadata) yoast = this.props.metadata;
		let defaultMeta = this.props.data.site.defaultMeta;

		let metadata = {
			title: yoast.title || this._translateMeta(defaultMeta.title),
			meta: [],
			link: [],
		};

		metadata.meta.push({ name: 'description', content: yoast.metadesc || this._translateMeta(defaultMeta.metadesc) });
		metadata.meta.push({
			name: 'keywords',
			content: yoast.metakeywords || this._translateMeta(defaultMeta.metakeywords),
		});
		metadata.link.push({ rel: 'canonical', href: yoast.canonical || (typeof window !== 'undefined' ? window.location.href : '') });
		if (yoast.noindex_nofollow === '1') metadata.meta.push({ name: 'robots', content: 'noindex,nofollow' });

		metadata.meta.push({
			property: 'og:title',
			content: yoast.social_title || this._translateMeta(defaultMeta.social_title),
		});
		metadata.meta.push({
			property: 'og:description',
			content: yoast.social_description || this._translateMeta(defaultMeta.social_description),
		});
		metadata.meta.push({ property: 'og:url', content: yoast.canonical || (typeof window !== 'undefined' ? window.location.href : '') });
		metadata.meta.push({
			property: 'og:image',
			content: yoast.social_image[0] || this._translateMeta(defaultMeta.social_image),
		});

		metadata.meta.push({ property: 'twitter:card', content: 'summary' });
		metadata.meta.push({
			property: 'twitter:title',
			content: yoast.social_title || this._translateMeta(defaultMeta.social_title),
		});
		metadata.meta.push({
			property: 'twitter:description',
			content: yoast.social_description || this._translateMeta(defaultMeta.social_description),
		});
		metadata.meta.push({ property: 'twitter:url', content: yoast.canonical || (typeof window !== 'undefined' ? window.location.href : '') });
		metadata.meta.push({
			property: 'twitter:image',
			content: yoast.social_image[0] || this._translateMeta(defaultMeta.social_image),
		});

		metadata.meta.push({ 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' });

		let alternative = this.props.state.currentTranslationPages[this.props.state.currentPage];
		if (alternative) {
			// eslint-disable-next-line
			this.languages.map((l) => {
				if (this.props.state.lang !== l && alternative.hasOwnProperty(l)) {
					metadata.link.push({ rel: 'alternate', href: (typeof window !== 'undefined' ? window.location.origin + alternative[l] : '') , hrefLang: l });
				}
			});
		}
		return metadata;
	}
	_translateMeta(meta) {
		let translates = this.props.state.translation[this.props.state.lang];
		if (translates[meta] !== undefined) {
			return translates[meta];
		} else {
			return meta;
		}
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

class ConnectedLayout extends Component {
	render() {
		return (
			<StaticQuery
				query={graphql`
					query {
						site {
							defaultMeta: siteMetadata {
								title
								metadesc
								metakeywords
								social_title
								social_description
								social_image
							}
						}
						languagesAvailable: allWordpressPage {
							group(field: polylang_current_lang_code) {
								field
								fieldValue
								totalCount
							}
						}
					}
				`}
				render={(data) => <Layout data={data} {...this.props} />}
			/>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConnectedLayout);
